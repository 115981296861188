<app-loader *ngIf="loader.showLoader"></app-loader>

<div [dir]="direction" style="height: 100%">
  <router-outlet></router-outlet>
</div>
<tour-step-template>
  <ng-template let-step="step">
    <mat-card
      [dir]="direction"
      style="box-shadow: none; width: fit-content"
      (click)="$event.stopPropagation()"
    >
      <mat-card-header style="flex-direction: column">
        <div style="display:flex;flex-direction: row;justify-content: space-between;align-items: center;margin-bottom: 10px">
          <h3 style="margin: 0 !important;font-weight: 500">
            {{"GUIDE.TITLE" | translate}}
          </h3>
          <button (click)="changeLanguage()" mat-mini-fab
                  matTooltip="{{_translate.currentLang === 'ar' ? 'change language to english' : 'تغيير إلي اللغة العربية'}}"
                  style="background-color: #6a259a;width: 32px;
                          height: 32px;" aria-label="Example icon button with a home icon">
            <mat-icon style="width: 18px;font-size: 18px">translate</mat-icon>
          </button>
        </div>

        <h3>
          {{ step.title | translate}}
        </h3>

      </mat-card-header>

      <mat-card-content
        class="mat-body"
        [innerHTML]="step.content | translate"
      ></mat-card-content>

      <mat-card-actions [class.no-progress]="!step.showProgress">
        <button
          mat-button
          class="prev"
          [disabled]="!tourService.hasPrev(step)"
          (click)="tourService.prev()"
        >
          {{ step.prevBtnTitle | translate }}
        </button>
        <ng-template [ngIf]="step.showProgress">
          <div class="progress">
            {{ tourService.steps?.indexOf(step) + 1 }} /
            {{ tourService.steps?.length }}
          </div>
        </ng-template>

        <ng-template
          [ngIf]="tourService.hasNext(step) && !step.nextOnAnchorClick"
        >
          <button class="next" (click)="tourService.next()" mat-button>
            {{ step.nextBtnTitle | translate }}
          </button>
        </ng-template>
        <ng-template [ngIf]="!tourService.hasNext(step)">
          <button mat-button (click)="end()">
            {{ step.endBtnTitle | translate }}
          </button>
        </ng-template>
      </mat-card-actions>
    </mat-card>
  </ng-template>
</tour-step-template>
