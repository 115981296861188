import {AuthService} from './../../auth/auth.service';
import {catchError, flatMap, retry} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {ProgressBarService} from '../components/header/progress-bar/progress-bar.service';
import {SnackComponent} from '../components/snack/snack.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  eTagRoutes: string[];
  constructor(private _auth: AuthService, public _dialog: MatDialog, private _progressBarService: ProgressBarService,
    private snackBar: MatSnackBar,
  ) {
    this.eTagRoutes = ['api/v1/portal-config/get-config'];
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('./assets') || request.url.includes('./certificates' ) ) {
      request = request.clone({
        url: `${request.url}`,

      });
    }
    else if (request.url.includes('http://localhost:8282')){
      request = request.clone({
        headers: request.headers
          .set('Content-Type', 'application/json')
          .set('Accept', 'application/json, text/plain')
          .set('Access-Control-Allow-Origin', 'http://localhost:8282'),
        url: `${request.url}`,
      });
    }
    else if (request.url.includes('translation.googleapis.com')) {
      request = request.clone({
        url: `${request.url}`,
      });
    }else if (request.url.includes('/upload/cloud/file')) {
      request = request.clone({
        headers: this.addHeadersForUpload(),
        url: `${environment.apiUrl}${request.url}`,
      });
    } else {
      const accessToken = JSON.parse(localStorage.getItem('ACCESS_TOKEN'));
      request = request.clone({
        headers: request.headers //this.addHeaders(request.url),
          .set('Content-Type', 'application/json;charset=UTF-8')
          .set('Authorization', `Bearer ${accessToken}`)
          .set('accept-language', localStorage.getItem('LANG'))
          .set('APP_TOKEN_HEADER', environment.appToken),
          // .set('DEVICE_ID' ,this._auth.deviceId),
        url: `${environment.apiUrl}${request.url}`,
      });
    }

    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        if (error.error?.status == 'Unauthorized') {
          // && error.error?.message.includes('JWT expired')
          return this._auth.refreshToken().pipe(
            flatMap((res) => {
              const accessToken = JSON.parse(localStorage.getItem('ACCESS_TOKEN'));
              let req = request.clone({
                headers: request.headers //this.addHeaders(request.url),
                .set('Content-Type', 'application/json;charset=UTF-8')
                .set('Authorization', `Bearer ${accessToken}`)
                .set('accept-language', localStorage.getItem('LANG'))
                .set('APP_TOKEN_HEADER', environment.appToken),
                // .set('DEVICE_ID' ,this._auth.deviceId),
                url: `${request.url}`
              });
              return next.handle(req).pipe(
                catchError((error: HttpErrorResponse) => {
                  //logout
                  this._auth.logout();
                  return throwError(new Error('error'));
                })
              );
            }));
        }
        // start print error message
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
          console.log(errorMessage);
        }
        this._progressBarService.hide();
        const printerCheckHealthError = 'http://localhost:8282/printer/health-check';
        const printerCheckHealthErrorIndex = error?.error?.message.indexOf(printerCheckHealthError);
        if (error?.error?.message.indexOf(printerCheckHealthErrorIndex) !== -1) {
          return
        }
        this.showSnack(error?.error?.message, 'error');
        return throwError(new Error(error?.error?.message));

      })
    );
  }

  addHeader(endPoint: any): HttpHeaders {
    // const currentUser = JSON.parse(window.localStorage.getItem('CURRENT_USER'));
    const accessToken = JSON.parse(localStorage.getItem('ACCESS_TOKEN'));
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      ...(accessToken && {Authorization: `Bearer ${accessToken}`,}),
      'accept-language': localStorage.getItem('LANG'),
      'APP_TOKEN_HEADER': environment.appToken,
    });
    if (this.eTagRoutes.includes(endPoint)) {
      HEADERS['If-None-Match'] = this.getMatchTag(endPoint) || null;
    }
    return HEADERS;
  }

  addHeadersForUpload(): HttpHeaders {
    // const currentUser = JSON.parse(window.localStorage.getItem('CURRENT_USER'));
    const accessToken = JSON.parse(localStorage.getItem('ACCESS_TOKEN'));
    const HEADERS = new HttpHeaders({
      ...(accessToken && {
        Authorization: `Bearer ${accessToken}`,
      }),
      'accept-language': localStorage.getItem('LANG'),
      'APP_TOKEN_HEADER': environment.appToken,
    });
    return HEADERS;
  }

  getMatchTag(endPoint) {
    let tag: string = null;
    const TAGS: any = JSON.parse(localStorage.getItem('TAGS')) || [];

    if (TAGS?.length > 0) {
      TAGS.forEach((TAG) => {
        if (TAG.endPoint === endPoint) {
          tag = TAG.eTag;
        }
      });
    }
    return tag;
  }

  showSnack(message = 'erorr', type): void {
    const options: any = {};
    if (type === 'success') {
      options.icon = 'check_circle';
      options.icon_color = '#00C853';
    } else if (type === 'error') {
      options.icon = 'cancel';
      options.icon_color = '#BF360C';
    } else if (type === 'info') {
      options.icon = 'info';
      options.icon_color = '#2196F3';
    }
    this.snackBar.openFromComponent(SnackComponent, {
      data: {
        message,
        icon: options.icon,
        icon_color: options.icon_color,
        action_icon: 'close',
      },
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
      duration: 30000,
    });
  }
}
