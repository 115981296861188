import {AuthService} from 'src/app/auth/auth.service';

export function appInitializer(authenticationService: AuthService) {
  return () =>
    new Promise((resolve) => {
      if (authenticationService.user) {
        authenticationService.refreshToken().subscribe().add(resolve);
      } else {
        resolve('');
      }
    });
}
