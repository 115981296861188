import {Directive, HostBinding, inject, Inject, InjectionToken, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {TourAnchorDirective} from 'ngx-ui-tour-core';
import {TourAnchorMatMenuDirective} from 'ngx-ui-tour-md-menu';



export const TOUR_ANCHOR_DIRECTIVE = new InjectionToken<TourAnchorDirective>('TourAnchorDirective');
export const TOUR_ANCHOR_DIRECTIVE_TYPE = new InjectionToken<Type<TourAnchorDirective>>('TourAnchorDirectiveType');

interface CustomTourAnchorDirective extends TourAnchorDirective, OnInit, OnDestroy {
  tourAnchor: string;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tourAnchor]',
  // All directive providers should be declared here because their instances should not be shared
  providers: [
    TourAnchorMatMenuDirective,

    {
      provide: TOUR_ANCHOR_DIRECTIVE,
      useFactory: () => {
        const type = inject(TOUR_ANCHOR_DIRECTIVE_TYPE);

        return inject(type);
      }
    }
  ],
  standalone: true
})
export class ProxyTourAnchorDirective implements OnInit, OnDestroy {

  @Input()
  public tourAnchor: string;

  @HostBinding('class.touranchor--is-active')
  public isActive = false;

  constructor(
    @Inject(TOUR_ANCHOR_DIRECTIVE)
    private readonly tourAnchorDirective: TourAnchorDirective
  ) {
    this.overrideShowHideMethods();
  }

  ngOnInit() {
    const tourAnchorDirective = this.tourAnchorDirective as CustomTourAnchorDirective;

    tourAnchorDirective.tourAnchor = this.tourAnchor;
    tourAnchorDirective.ngOnInit();
  }

  ngOnDestroy() {
    (this.tourAnchorDirective as CustomTourAnchorDirective).ngOnDestroy();
  }

  private overrideShowHideMethods() {
    const origShowFn = this.tourAnchorDirective.showTourStep.bind(this.tourAnchorDirective),
      origHideFn = this.tourAnchorDirective.hideTourStep.bind(this.tourAnchorDirective);

    this.tourAnchorDirective.showTourStep = step => {
      this.isActive = true;
      origShowFn(step);
    };
    this.tourAnchorDirective.hideTourStep = () => {
      this.isActive = false;
      origHideFn();
    };
  }

}
