import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class MatPaginatorIntlCro extends MatPaginatorIntl {
  constructor(public _translate: TranslateService) {
    super();
  }

  itemsPerPageLabel = this._translate.instant('COMMON.ITEM_PER_PAGE');
  nextPageLabel = this._translate.instant('COMMON.NEXT_PAGE_LABEL');
  previousPageLabel = this._translate.instant('COMMON.PREVIOUS_PAGE_LABEL');
  firstPageLabel = this._translate.instant('COMMON.FIRST_PAGE_LABEL');
  lastPageLabel = this._translate.instant('COMMON.LAST_PAGE_LABEL');

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 ' + this._translate.instant('COMMON.OF') + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return (
      startIndex +
      1 +
      '  -  ' +
      endIndex +
      this._translate.instant('COMMON.OF') +
      '   ' +
      length
    );
  };
}
